import {AppleButton, BaseButton} from "./button.styles";

export const BUTTON_TYPE_CLASSES = {
    base: 'base',
    google: 'google-sign-in',
    apple: 'apple'
}


const getButtonType = (type = BUTTON_TYPE_CLASSES.base) => {
    return {
        [BUTTON_TYPE_CLASSES.base]: BaseButton,
        [BUTTON_TYPE_CLASSES.google]: BaseButton,
        [BUTTON_TYPE_CLASSES.apple]: AppleButton,
    }[type]
}

const Button = ({children, buttonType, ...otherProps}) => {
    const CustomButton = getButtonType(buttonType);
    return (
        <CustomButton {...otherProps}>{children}</CustomButton>
    )
}

export default Button