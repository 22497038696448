import styled, {css} from "styled-components";
import MosqueImage from '../../../assets/mosqeu.jpg'
import {PRIMARY_COLOR} from "../../shared/color.share";

const FadeInAnimation = css`
  animation: fadeIn 2s ease-in;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`
export const Section = styled.section`
  width: 100%;
  background-image: linear-gradient(#2e1248, #000);
`

export const PageName = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  background: url('${MosqueImage}') no-repeat center center/cover;


  h1 {
    color: ${PRIMARY_COLOR};
    font-size: 4.5rem;
    margin: 0 10px;
    font-weight: lighter;
    ${FadeInAnimation}
  }

  h2 {
    color: #d3bde9;
    font-size: 2rem;
    font-weight: lighter;
    ${FadeInAnimation}
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }

  & * {
    z-index: 1;
  }
`

export const PageDetail = styled.div`
  display: flex;
  height: 100vh;
  color: white;
  padding: 15px 15px;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media screen and (max-width: 768px) {
    height: 100%; //FOR EXCEEDING TEXT OUT OF BOX
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-end;
  }
`
export const Detail = styled.p`
  max-width: 700px;
  flex: 2;
  min-height: 0;
  line-height: 30px;
  padding: 0 20px;
  letter-spacing: 0.5px;
  text-align: start;
`

export const DownloadSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`