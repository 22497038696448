import MOBILE_MODE_ACTION_TYPES from "./mobile-mode.types";


const INITIAL_STATE = {
    isMobileMode: false,
};

export const mobileModeReducer = (state = INITIAL_STATE, action)=> {
    const { type, payload } = action;
    switch (type) {
        case MOBILE_MODE_ACTION_TYPES.SET_IS_MOBILE_MODE:
            return { ...state, isMobile: payload };
        default:
            return state
    }
};