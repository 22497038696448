import {Route, Routes} from "react-router-dom";
import Navbar from "./components/navbar/navbar.component";
import Home from "./components/routers/home/home.component";
import PrivacyPolicy from "./components/routers/privacy-policy/privacy-policy.component";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {setIsMobileMode} from "./store/mobile-mode/mobile-mode.action";

function App() {
  const dispatch = useDispatch();

  const [isMobileSize, setIsMobileSize] = useState(false)
  useEffect(()=>{
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    const handleResize = (event:MediaQueryListEvent)=>{
      setIsMobileSize(event.matches)
    }
    setIsMobileSize(mediaQuery.matches)

    mediaQuery.addEventListener('change', handleResize);

    dispatch(setIsMobileMode(mediaQuery.matches))

    return ()=>{
      mediaQuery.removeEventListener('change', handleResize)
    }
  },[isMobileSize]);

  return (<Routes>
    <Route path='/' element={<Navbar/>}>
      <Route index element={<Home/>}/>
      <Route path='privacy-policy' element={<PrivacyPolicy/>}/>
    </Route>
  </Routes>)

}

export default App;
