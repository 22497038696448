import {Link} from 'react-router-dom';

import './menu.styles.css'
import {useState} from "react";

const MenuBurger = () => {
    const [isOpen, setIsOpen] = useState(false);

    const ItemClickHandler = () => {
        setIsOpen(false)
    }

    const onChangeHandler = ()=>{
        isOpen ? setIsOpen(false):setIsOpen(true)
    }
    return (<div className="menu-wrap">
            <input onChange={onChangeHandler} checked={isOpen} type="checkbox" className="toggler"/>
            <div className="hamburger">
                <div></div>
            </div>
            <div className="menu">
                <div>
                    <div>
                        <ul>
                            <li onClick={ItemClickHandler}><Link to='/'>Home</Link></li>
                            <li onClick={ItemClickHandler}><Link to='/privacy-policy'>PRIVACY POLICY</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MenuBurger