import {Detail, DownloadSection, PageDetail, PageName, Section} from "./home.styles";
import {Fragment} from "react";
import Button, {BUTTON_TYPE_CLASSES} from "../../button/button.component";

const Home = ()=>{
    const navigateToDownloadPageHandler = ()=>window.open("https://apps.apple.com/us/app/almond-world-of-words/id6464568044", "_blank")
    return(<Fragment>
            <Section>
                <PageName>
                    <h1>
                        almond
                    </h1>
                    <h2>
                        world of words
                    </h2>
                </PageName>
            </Section>
        <Section>
            <PageDetail>
                <Detail>
                    The game "Atmosphere" is a delightful experience centered around deserts, with a vibrant and soil-colored atmosphere that draws inspiration from the beloved Aladdin movie.
                    As players embark on their adventure, they will encounter intricately designed levels adorned with exquisite details reminiscent of the Aladdin movie's visual style. The warm earth tones and rich textures create a visually captivating environment, evoking a sense of wonder and nostalgia.
                    In this imaginative game, players will have the opportunity to interact with various characters and objects, each carefully crafted to reflect the charm and allure of the Aladdin universe. From flying carpets to magical lamps, every element is thoughtfully incorporated to transport players into a realm of Arabian-inspired sweetness.
                </Detail>
                <DownloadSection>
                    <p>You can download the game from below stores</p>
                    <Button buttonType={BUTTON_TYPE_CLASSES.apple} onClick={navigateToDownloadPageHandler}>
                        <i className="fa-brands fa-apple fa-2xl"></i>
                        <p>Available on the <br/> <span className="big-txt">App Store</span></p>
                    </Button>
                </DownloadSection>
            </PageDetail>
        </Section>
    </Fragment>)
}

export default Home
