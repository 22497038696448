import styled from 'styled-components';
import BuildingImage from '../../../assets/colorful-mosqeu.png'
import {PRIMARY_COLOR} from "../../shared/color.share";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 90px;
`
export const HeaderImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 80%;
  height: 350px;
  background: url('${BuildingImage}') no-repeat 0 38%/cover;
  border-radius: 20px;
  
  h1{
    color: ${PRIMARY_COLOR};
    font-size: 3rem;
    font-weight: lighter;
  }
  
  h2{
    color: #d3bde9;
    font-size: 1.5rem;
    font-weight: lighter;
    margin-bottom:30px;
  }
  
  &:before {
    content: '';
    position: absolute;
    width: 80%;
    height: 350px;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.6);}

  & * {
    z-index: 1;
  }
`

export const DetailContainer = styled.div`
  width: 80%;
  margin: 20px;
  font-family: "Helvetica Neue";
  line-height: 28px;

  h1, h2 {
    font-weight: 700;
  }

  h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  
  h2 {
    font-size: 32px;
    line-height: 48px;
    display: block;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    font-weight: bold;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }
`