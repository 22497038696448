import {LogoContainer, NavigationContainer, NavLink, NavLinks} from "./navbar.styles";
import {Fragment, useEffect, useState} from "react";
import {Outlet, useLocation} from "react-router-dom";
import MenuBurger from "../menu/menu.component";
import {useSelector} from "react-redux";
import {selectCurrentScreenMode} from "../../store/mobile-mode/mobile-mode.selector";

const Navbar = () => {
    const isMobileMode = useSelector(selectCurrentScreenMode);
    const [isPrivacy, setIsPrivacy] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/privacy-policy' && !isPrivacy)
            setIsPrivacy(true)
        else if (isPrivacy)
            setIsPrivacy(false)
    }, [location])

    return (<Fragment>
        <NavigationContainer hasBack={isPrivacy}>
            <LogoContainer to='/'>
                <h1>ALMOND</h1>
            </LogoContainer>
            {isMobileMode ? <MenuBurger/> : (<NavLinks>
                <NavLink to='/privacy-policy'>
                    <div>PRIVACY POLICY</div>
                </NavLink>
            </NavLinks>)}
        </NavigationContainer>
        <Outlet/>
    </Fragment>)
}

export default Navbar
