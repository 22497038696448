import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {PRIMARY_COLOR} from '../shared/color.share'

export let NavigationContainer = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  background-color: ${(props) => props.hasBack ? "#333" : 'transparent'};
  opacity: 0.8;
  position: fixed;
  justify-content: space-between;
  margin-bottom: 25px;
  z-index: 2;
`;
export const LogoContainer = styled(Link)`
  height: 100%;
  width: 70px;
  padding: 10px;

  h1 {
    color: ${PRIMARY_COLOR};
  }
`;
export const NavLinks = styled.div`
  width: 50%;
  height: 100%;

  @media screen and (max-width: 768px) {
    width: 60%;
  }

  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const NavLink = styled(Link)`
  padding: 10px 15px;
  cursor: pointer;
`;
