import {applyMiddleware, compose, createStore} from "redux";
import { rootReducer} from './root-reducer'
const loggerMiddleware = (store)=>(next)=>(action)=>{
    if(!action.type)
        return next(action)

    console.log('type: ', action.type);
    console.log('payload: ', action. payload);
    console.log('currentState: ', store.getState());

    next(action);

    console.log(store.getState())
}



console.log('env', process.env.NODE_ENV)
const middlewares = [process.env.NODE_ENV === 'development' && loggerMiddleware].filter(Boolean)
const composeEhancer =  //ROR REDUX DEV TOOL EXTENSION
    (process.env.NODE_ENV !== 'production' &&
window &&
window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
compose;

const composedEnhancers = composeEhancer(applyMiddleware(...middlewares));
export const store = createStore(rootReducer, undefined, composedEnhancers);