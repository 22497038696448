import styled from 'styled-components';

export const BaseButton = styled.button`
  min-width: 165px;
  width: auto;
  height: 50px;
  letter-spacing: 0.5px;
  line-height: 50px;
  padding: 0 35px 0 35px;
  font-size: 15px;
  background-color: white;
  color: black;
  text-transform: uppercase;
  font-family: 'Open Sans Condensed';
  font-weight: bolder;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: gray;
    color: black;
    border: 1px solid black;
  }
`;

export const AppleButton = styled.button`
  width: 45%;
  max-width: 160px;
  display: flex;
  color: black;
  margin: 20px 10px;
  padding: 5px 0;
  text-align: left;
  border-radius: 5px;
  border: none;
  transition: background-color 0.25s linear, color 0.25s linear;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  font-family: "Lucida Grande", sans-serif;
  font-size: 10px;
  text-transform: uppercase;

  &:hover{
    background-color: #454545;
    color: white;
  }
  i {
    width: 20%;
    text-align: center;
    font-size: 28px;
    margin-right: 7px;
  }
  .big-txt {
    font-size: 17px;
    text-transform: capitalize;
  }
`

